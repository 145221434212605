<template>
  <div>
    <app-Main>
      <!-- 顶部查询 -->
      <template v-slot:main-top>
        <div v-if="isShow" class="querybar">
          <el-form label-width="80px" :model="form">
            <div class="box">
                <el-form-item label="工单编号">
                  <el-input v-model="form.order" class="input" placeholder="工单编号"></el-input>
                </el-form-item>
                <el-form-item label="工单类型">
                  <el-select class="input" placeholder="请选择类型" v-model="form.value">
                    <el-option v-for="(item, index) in options" :key="index" :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="查询时间">
                  <el-date-picker
                    class="input"
                    @change="dateChange"
                    v-model="form.times"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <div class="date-filter">
                    <span :class="{'active': spanIndex === index}" @click="dateClick(index)" v-for="(item, index) in dateSelection" :key="index">{{item}}</span>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button @click="queryClick" type="primary" size="small">查询</el-button>
                  <el-button @click="resetClick" size="small">重置</el-button>
                </el-form-item>
            </div>
          </el-form>
        </div>
      </template>
      <!-- 列表 -->
      <template v-slot:main-content>
        <div v-if="isShow">
          <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
              <el-menu-item index="0">全部</el-menu-item>
              <el-menu-item index="1">待您处理</el-menu-item>
              <el-menu-item index="2">正在为您处理</el-menu-item>
              <el-menu-item index="3">已完成</el-menu-item>
              <el-menu-item index="4">已关闭</el-menu-item>
            </el-menu>
          </div>
          <div class="table">
            <el-table
            :data="workOrderData.data"
            :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}"
            :row-style="{height: '54px'}"
            >
              <el-table-column label="序号" width="80">
                <template slot-scope="scope"><span>{{scope.$index+(workOrderData.current_page - 1) * workOrderData.per_page + 1}}</span></template>
              </el-table-column>
              <el-table-column width="320" label="工单编号" prop="matter_no"></el-table-column>
              <el-table-column label="标题" prop="title"></el-table-column>
              <el-table-column label="问题类型">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.typedata === '1'">底单申请</span>
                    <span v-if="scope.row.typedata === '2'">物流异常</span>
                    <span v-if="scope.row.typedata === '3'">其他问题</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.status === '1'">待您处理</span>
                    <span v-if="scope.row.status === '2'">正在为您处理</span>
                    <span v-if="scope.row.status === '3'">已完成</span>
                    <span v-if="scope.row.status === '4'">已关闭</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="220" label="创建时间">
                <template slot-scope="scope"><span>{{timeConversion(scope.row.createtime)}}</span></template>
              </el-table-column>
              <el-table-column width="220" label="修改时间">
                <template slot-scope="scope"><span>{{timeConversion(scope.row.updatetime)}}</span></template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div class="caozuo">
                    <span @click="viewDetails(scope.row)">查看详情</span>
                    <span v-if="scope.row.status === '1' || scope.row.status === '2'" @click="close(scope.row.matter_no, 4)">关闭</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="currentChange"
                :page-size="workOrderData.per_page"
                :current-page="workOrderData.current_page"
                layout="prev, pager, next"
                :total="workOrderData.total">
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 详情页 -->
        <div v-else class="details">
          <!-- 状态 -->
          <div class="status pdmr24">
            <span>
              状态：
              <span v-show="workOrderItem.status === '1'">待您处理</span>
              <span v-show="workOrderItem.status === '2'">正在为您处理</span>
              <span v-show="workOrderItem.status === '3'">已完成</span>
              <span v-show="workOrderItem.status === '4'">用户关闭</span>
            </span>
            <div>
              <el-button @click="jiedan(workOrderItem.matter_no, 3)" v-if="workOrderItem.status === '1' || workOrderItem.status === '2'" type="primary">结单</el-button>
              <el-button v-else @click="backList">返回列表</el-button>
            </div>
          </div>
          <!-- 工单信息 -->
          <div class="info pdmr24">
            <h3>工单信息</h3>
            <p>工单编号 ：{{workOrderItem.matter_no}}</p>
            <p>工单标题 ：{{workOrderItem.title}}</p>
            <p>问题类型 ：
              <span v-show="workOrderItem.typedata === '1'">底单申请</span>
              <span v-show="workOrderItem.typedata === '2'">物流异常</span>
              <span v-show="workOrderItem.typedata === '3'">其他问题</span>
            </p>
            <p>创建时间 ：{{timeConversion(workOrderItem.createtime)}}</p>
            <p>联系人 ：{{workOrderItem.user_name}}</p>
            <p>联系手机 ：{{$store.getters.getUserInfo.username}}</p>
          </div>
          <!-- 解决记录 -->
          <div class="record pdmr24">
            <h3>解决记录</h3>
            <div class="concat_list">
              <div class="concat_item" v-for="(item, index) in record" :key="index">
                <div class="item_left">
                  <el-avatar
                    :size="48"
                    :src="userInfo.avatar"
                    @error="errorHandler"
                  >
                    <img
                      src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                    />
                  </el-avatar>
                </div>
                <div class="item_center">
                  <div class="name">{{workOrderItem.username}}</div>
                  <div class="content">{{item.content}}</div>
                  <div v-if="item.matter_images.length > 0">
                    <el-image class="recordImage"   v-for="(i, k) in item.matter_images" :key="k" :src="i" :preview-src-list="item.matter_images"></el-image>
                  </div>
                </div>
                <div class="item_right">{{timeConversion(item.createtime)}}</div>
              </div>
            </div>
          </div>
          <!-- 继续沟通 -->
          <div v-if="workOrderItem.status === '1' || workOrderItem.status === '2'" class="continue pdmr24">
            <h3>继续沟通</h3>
            <div class="input">
              <el-input v-model="textareaVal" type="textarea" placeholder="输入沟通内容" :rows="7" resize="none"></el-input>
            </div>
            <div class="upImg">
              <el-upload
                action=""
                list-type="picture-card"
                :http-request="uploadFile"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :before-upload="beforePicUpload"
                :file-list="fileList">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div>
              <el-button @click="submit" type="primary">提交</el-button>
              <el-button @click="backList">返回列表</el-button>
            </div>
          </div>
        </div>
      </template>
    </app-Main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { matterSelect, matterStatus, resolve, matterWrite, imgsHome } from 'network/api'
import { timeConversion } from 'utils/utils'

export default {
  components: {
    AppMain
  },
  inject: ['mainReload'],
  data () {
    return {
      activeIndex: '0',
      options: ['底单申请', '物流异常', '其他问题'],
      dateSelection: ['今天', '昨天', '最近7天', '最近30天'],
      form: {
        order: '',
        value: '',
        times: []
      },
      isShow: true,
      spanIndex: null,
      currentPage: 1, // 当前页
      workOrderData: {},
      workOrderItem: null,
      record: null, // 工单记录
      textareaVal: '', // 继续沟通的内容
      uploadImg: [],
      fileList: [],
      matterimages: ''
    }
  },
  computed: {
    // 获取用户信息
    userInfo () {
      return this.$store.getters.getUserInfo
    },
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  created () {
    // 初始化时间选择器
    const dayStar = new Date()
    const dayEnd = new Date()
    dayStar.setTime(dayStar.getTime())
    dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
    this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
    this.spanIndex = 0
    // 初始化列表
    this.getMatterSelect()
  },
  methods: {
    /*  */
    /*  */
    /* 继续沟通上传图片方法开始 */
    // 上传图片回调
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-5)
    },
    // 删除上传图片回调
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    // 自定义上传
    uploadFile (file) {
      imgsHome(file.file).then(res => {
        if (res.code === 1) {
          // 将上传成功得到的url 存入一个数组 只有5个对应fileList
          this.uploadImg.push(res.data)
          this.uploadImg = this.uploadImg.slice(-5)
          // 将uploadImg数组下标里的url 放到fileList数组对应下标
          for (let i = 0; i < this.uploadImg.length; i++) {
            this.fileList[i].realUrl = this.uploadImg[i]
          }
        }
      })
    },
    // 上传图片之前的钩子
    beforePicUpload (file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg' || testmsg === 'JPG' || testmsg === 'png' || testmsg === 'PNG'
      const isLt50M = file.size / 1024 / 1024 < 2
      if (!extension) {
        this.$message({
          message: '上传文件只能是jpg或者png格式!',
          type: 'error'
        })
        return false // 必须加上return false; 才能阻止
      }
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过 2MB!',
          type: 'error'
        })
        return false
      }
      return extension || isLt50M
    },
    /* 继续沟通上传图片方法开始 */
    /*  */
    /*  */
    // menu 改变回调
    selectChange (index) {
      this.activeIndex = index
      this.getMatterSelect()
    },
    // 分页改变
    currentChange (val) {
      this.currentPage = val
      this.getMatterSelect()
    },
    errorHandler () {
      return true
    },
    // 查询时间改变
    dateChange (val) {
      this.form.times = val
      this.getMatterSelect()
    },
    // 选择日子
    dateClick (index) {
      this.spanIndex = index
      var dayStar = new Date()
      var dayEnd = new Date()
      switch (index) {
        // 今天
        case 0:
          dayStar.setTime(dayStar.getTime())
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getMatterSelect()
          break
        // 昨天
        case 1:
          dayStar.setTime(dayStar.getTime() - 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime())
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getMatterSelect()
          break
        // 最近7天
        case 2:
          dayStar.setTime(dayStar.getTime() - 7 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getMatterSelect()
          break
        // 最近30天
        case 3:
          dayStar.setTime(dayStar.getTime() - 30 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getMatterSelect()
          break
      }
    },
    // 工单筛选请求
    getMatterSelect () {
      matterSelect({
        status: this.activeIndex,
        matter_no: this.form.order,
        matter_type: this.form.value,
        createtime: this.form.times,
        page: this.currentPage
      }).then(res => {
        this.workOrderData = res.data
      })
    },
    // 查询
    queryClick () {
      this.getMatterSelect()
    },
    // 重置
    resetClick () {
      this.mainReload()
    },
    // 修改工单状态
    postMatterStatus (no, status) {
      matterStatus({
        matter_no: no,
        status: status
      }).then(res => {
      })
    },
    // 关闭工单
    close (no, status) {
      this.$confirm('是否关闭工单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postMatterStatus(no, status)
        this.getMatterSelect()
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      })
    },
    getMatterResolve () {
      resolve({ id: this.workOrderItem.id }).then(res => {
        this.record = res.data
        console.log(res.data, 'aaa')
        // for (let i = 0; i < this.record.length; i++) {
        //   if (this.record[i].matter_images) {
        //     let arr = this.record[i].matter_images.split(',')
        //     this.record[i].images = arr
        //     arr = []
        //   } else {
        //     this.record[i].images = []
        //   }
        // }
      })
    },
    // 查看详情
    viewDetails (data) {
      this.workOrderItem = data
      // 获取记录列表
      this.getMatterResolve()
      this.isShow = false
    },
    // 返回列表
    backList () {
      this.isShow = true
    },
    // 结单
    jiedan (no, status) {
      this.$confirm('是否关闭工单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 改变订单状态
        this.postMatterStatus(no, status)
        this.workOrderItem.status = status
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      })
    },
    // 提交
    submit () {
      if (!this.textareaVal) {
        this.$message.error('请输入沟通内容')
        return false
      }
      for (let i = 0; i < this.fileList.length; i++) {
        var int = this.fileList[i].realUrl.indexOf('/upload')
        var images = this.fileList[i].realUrl.slice(int)
        this.matterimages += images + ','
      }
      this.matterimages = this.matterimages.substring(0, this.matterimages.length - 1)
      matterWrite({
        matter_type: this.workOrderItem.typedata,
        title: this.workOrderItem.title,
        content: this.textareaVal,
        matterimages: this.matterimages,
        mobile: this.workOrderItem.mobile,
        matter_no: this.workOrderItem.matter_no
      }).then(res => {
        // console.log(res.data)
        if (res.code === 1) {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
          this.matterimages = ''
          this.textareaVal = ''
          this.fileList = []
          this.uploadImg = []
          this.getMatterResolve()
        }
      })
    }
  }
}
</script>

<style scoped src="styles/components/queryHeader.css">
</style>

<style scoped src="styles/views/workOrder/myOrder.css">
</style>

<style>
.table .el-table tbody tr:hover > td {
  background-color: #e6f7ff;
}
</style>

<style>
/* 上传图片框样式 */
.upImg .el-upload--picture-card{
  width: 100px;
  height: 100px;
  font-size: 16px !important;
}
.upImg .el-upload{
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}
.upImg .el-upload-list--picture-card .el-upload-list__item{
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}

.upImg .el-upload-list--picture-card .el-upload-list__item-actions:hover {
  opacity: 1;
  font-size: 16px;
}

.upImg .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}
.upImg .avatar{
  width: 100px;
  height: 100px;
}
</style>
